.App {
  background-color: #FFFCF0;
    -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
}
.flexbox-container {
    display: flex;
    flex-direction: row;
}

body {
  background-color: #FFFCF0;
    -ms-overflow-style: none;
      /* for Internet Explorer, Edge */
      scrollbar-width: none;
      /* for Firefox */
      overflow-y: scroll;
}

html {
  background-color: #FFFCF0;
}

.root {
  background-color: #FFFCF0;
}

.App-logo {
  height: 20;
  pointer-events: none;
}

.app::-webkit-scrollbar {
  display: none;
}


.p1 {
          font-family: "GaramondExtraBold", Times, serif;
            text-align: left;
                  filter: drop-shadow(1px 1px 0.5px #EBDCBB) drop-shadow(-1px -1px 0.5px hsl(0, 0%, 100%));
                    font-size: 40px;
                    position: absolute;
                      top: -30px;
                        left: 40px;
        }

.nuemorphic{
      filter: drop-shadow(2px 2px 0.5px #EBDCBB)  drop-shadow(-2px -2px 0.5px #FFFFFF);
        height: 10vmin;
}

.nuemorphicLogo {
  filter: drop-shadow(2px 2px 0.5px #EBDCBB) drop-shadow(-2px -2px 0.5px #FFFFFF);
  height: 5vmin;

                      position: absolute;
                        bottom: 25px;
}

.nuemorphicCode {
  filter: drop-shadow(3px 3px 1px #EBDCBB) drop-shadow(-3px -3px 1px #FFFFFF);
  height: 352px;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
      background-repeat: no-repeat;
      animation: shimmer 2.5s infinite;
              z-index: 1;
                grid-column: 1;
                  grid-row: 1;
                            will-change: filter;
}

.nuemorphicCodeShimmerless {
  filter: drop-shadow(3px 3px 1px #EBDCBB) drop-shadow(-3px -3px 1px #FFFFFF);
  height: 352px;
  z-index: 1;
  grid-column: 1;
  grid-row: 1;
}

.container_row {
  display: grid;
}

.rectang {
border-radius: 10px;
  background: #FFFCF0;
  box-shadow: 4px 4px 5px #EBDCBB,
    -4px -4px 5px #ffffff;
  height: 352px;  
  width: 352px;
  z-index: 0;
    grid-column: 1;
      grid-row: 1;
}

.codeLogo {
  filter: drop-shadow(3px 3px 1px #EBDCBB) drop-shadow(-3px -3px 1px #FFFFFF);
  height: 109px;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  z-index: 1;
  grid-column: 1;
  grid-row: 1;
    padding-top: 124px;
      padding-right: 124px;
      padding-left: 122px;
                will-change: filter;
}

.codeLogoShimmerless {
  filter: drop-shadow(3px 3px 1px #EBDCBB) drop-shadow(-3px -3px 1px #FFFFFF);
  height: 109px;
  z-index: 1;
  grid-column: 1;
  grid-row: 1;
  padding-top: 124px;
  padding-right: 124px;
  padding-left: 122px;
  will-change: filter;
}

.shimmerLogo {
  filter: drop-shadow(4px 4px 2px #EBDCBB) drop-shadow(-4px -4px 2px #FFFFFF);
  height: 30vmin;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
      background-repeat: no-repeat;
      animation: shimmer 2.5s infinite;
        position: relative;
          bottom: 45px;
          will-change: filter;
}

.shimmerlessLogo {
  filter: drop-shadow(4px 4px 2px #EBDCBB) drop-shadow(-4px -4px 2px #FFFFFF);
  height: 30vmin;
  position: relative;
  bottom: 45px;
  will-change: filter;
}

.fire{
      height: 36px;
      position: relative;
                    top: 25px;
                    right: 1px;
}

.loadingLogo {
  filter: drop-shadow(4px 4px 2px #EBDCBB) drop-shadow(-4px -4px 2px #FFFFFF);
  height: 30vmin;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  position: relative;
}

.loadingLogoShimmerless {
  filter: drop-shadow(4px 4px 2px #EBDCBB) drop-shadow(-4px -4px 2px #FFFFFF);
  height: 30vmin;
  position: relative;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}
.nuemorphicBadge {
  filter: drop-shadow(3px 3px 2px #EBDCBB) drop-shadow(-3px -3px 2px #FFFFFF);
    max-width: 50%;
    height: auto;
    position: relative;
}

.nuemorphicBadgeHome {
  filter: drop-shadow(3px 3px 2px #EBDCBB) drop-shadow(-3px -3px 2px #FFFFFF);
  max-width: 50%;
  height: auto;
  position: relative;
  top: 90px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFFCF0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #6B5E47;
        filter: drop-shadow(5px 5px 2px #EBDCBB) drop-shadow(-5px -5px 2px #FFFFFF);
          text-align: center;
}

.App-link {
  color: #00FA92;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shimmer {
  color: #6B5E47;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
        filter: drop-shadow(2px 2px 2px #EBDCBB) drop-shadow(-2px -2px 2px #FFFFFF);
          font-family: "OpenSansExtraBold", sans-serif;
            text-align: center;
}

.noShimmer{
    color: #6B5E47;
      display: inline-block;
      font-size: 50px;
      filter: drop-shadow(2px 2px 2px #EBDCBB) drop-shadow(-2px -2px 2px #FFFFFF);
      font-family: "OpenSansExtraBold", sans-serif;
      text-align: center;
}

.prayertag {
  color: #6B5E47;
  display: inline-block;
  font-size: 38px;
  filter: drop-shadow(2px 2px 2px #EBDCBB) drop-shadow(-2px -2px 2px #FFFFFF);
  font-family: "OpenSansExtraBold", sans-serif;
  text-align: center;
            position: relative;
              top: 15px;
}
.displayName{
  color: #6B5E47;
    display: inline-block;
      font-size: 30px;
        filter: drop-shadow(2px 2px 2px #EBDCBB) drop-shadow(-2px -2px 2px #FFFFFF);
        font-family: "OpenSansExtraBold", sans-serif;
        text-align: center;
          position: relative;
            top: -17px;

}

.scanTo {
  color: #6B5E47;
  display: inline-block;
  font-size: 20px;
  filter: drop-shadow(2px 2px 2px #EBDCBB) drop-shadow(-2px -2px 2px #FFFFFF);
  font-family: "OpenSansExtraBold", sans-serif;
  text-align: center;
  position: relative;
  bottom: -15px;

}

.pct {
  color: #6B5E47;
  display: inline-block;
  font-size: 30px;
  filter: drop-shadow(2px 2px 2px #EBDCBB) drop-shadow(-2px -2px 2px #FFFFFF);
  font-family: "OpenSansExtraBold", sans-serif;
  text-align: center;
  position: relative;
  top: -25px;

}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left
  }
}

@font-face {
  font-family: 'GaramondExtraBold';
  src: local('MyFont'), url(./EBGaramond-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSansExtraBold';
  src: local('MyFont'), url(./OpenSans-ExtraBold.ttf) format('truetype');
}